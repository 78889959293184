import './webpack-imports';
import './sentry';
import './custom-elements';
import { applyPolyfills } from './polyfills';
import barba from '@barba/core';
import { initBaseView } from './views/_base';
import lazyView from './views/_lazy';
import { createLayoutGrid } from './utils/grid';
import { preloader } from './components/preloader';
import { timeout } from './utils/timeout';

import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import IndexOnceTransition from './transitions/index-once';
import LandingOnceTransition from './transitions/landing-once';
import MapOnceTransition from './transitions/map-once';

import IndexToLandingTransition from './transitions/index-to-landing';

import LandingToIndexTransition from './transitions/landing-to-index';
import LandingToMapTransition from './transitions/landing-to-map';

import MapToIndexTransition from './transitions/map-to-index';
import MapToLandingTransition from './transitions/map-to-landing';

import mapMemoryControlInit from './inits/map-memory-control';

import LandingPageView from './views/landing';
import IndexPageView from './views/index';
import MapPageView from './views/map';

import sw from './sw';

document.documentElement.classList.add('js-ready');

sw.register();

applyPolyfills().then(async () => {
    if (history.scrollRestoration) {
        history.scrollRestoration = 'manual';
    }

    initBaseView();
    gsap.registerPlugin(ScrollToPlugin);
    mapMemoryControlInit();

    await timeout(1000);
    preloader.leave().then(() => {
        if (NODE_ENV === 'development') {
            createLayoutGrid();
        }

        barba.init({
            cacheIgnore: true,
            transitions: [
                IndexOnceTransition,
                MapOnceTransition,
                LandingOnceTransition,
                IndexToLandingTransition,
                LandingToIndexTransition,
                LandingToMapTransition,
                MapToIndexTransition,
                MapToLandingTransition,
            ],
            views: [
                LandingPageView,
                IndexPageView,
                MapPageView,
                // lazyView('index-page', () =>
                //     import(/* webpackChunkName: "index-page", webpackPrefetch: true */ './views/index').then(
                //         (m) => m.default,
                //     ),
                // ),
                // lazyView('map-page', () =>
                //     import(/* webpackChunkName: "map-page", webpackPrefetch: true */ './views/map').then(
                //         (m) => m.default,
                //     ),
                // ),
            ],
            prefetchIgnore: true,
            preventRunning: true,
            timeout: 5000,
            debug: NODE_ENV === 'development',
        });
    });
});
