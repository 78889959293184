import { h } from 'preact';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { AnimatePresence, motion, useWillChange } from 'framer-motion';
import CountUp from 'react-countup';
import type { ProjectInfo } from '@/data/projects';
import classNames from 'classnames';
import { CloseIcon } from '../svg/CloseIcon';
import { tp } from '@/typograf';
import { countDecimals } from '@/utils/strings';
import ClipReveal from '../ClipReveal/ClipReveal';
import { WhiteArrowIcon } from '../svg/WhiteArrowIcon';
import { fadeInVariants } from '../ProductionTabs/production-tabs-animation-variants';
import { getCSSCustomProp } from '@/utils/css';
import { useCitiesAndTpsContext } from '../CitiesAndTpsElement/CitiesAndTpsContext';
import { debounce } from 'lodash';

const EXIT_DURATION = 0.5;

const transition = {
    duration: 0.67,
    ease: [0.33, 0, 0.67, 1],
};

const data = [
    {
        label: 'НОРМА (МАКС)',
        value: 370,
        color: 'linear-gradient(168deg, #99CC5A 18.73%, #56AB2F 81.72%)',
    },
    { label: 'ЗОЛА-УНОСА (НТЭЦ-3)', value: 211, color: '#afb2b6' },
    { label: 'КИРПИЧ КЕРАМИЧЕСКИЙ', value: 127, color: '#d9dbe0' },
    { label: 'ГАЗОБЕТОН АВТ.ТВ.', value: 57, color: '#d9dbe0' },
    { label: 'ПОРТЛАНД ЦЕМЕНТ', value: 37, color: '#d9dbe0' },
    { label: 'ЗОЛА-УНОСА (НТЭЦ-5)', value: 35, color: '#afb2b6' },
];

const tableData = [
    { station: 'Новосибирская ТЭЦ-5', date: '01.10.24', value: 35 },
    { station: 'Красноярская ТЭЦ-2', date: '25.06.24', value: 54 },
    { station: 'Красноярская ГРЭС-2', date: '27.09.24', value: 60 },
    { station: 'Абаканская ТЭЦ', date: '18.04.24', value: 66 },
    { station: 'Красноярская ТЭЦ-1', date: '23.12.24', value: 87 },
    { station: 'Красноярская ТЭЦ-3', date: '30.08.23', value: 93 },
    { station: 'Барнаульская ТЭЦ-3', date: '02.10.23', value: 101 },
    { station: 'Рефтинская ГРЭС', date: '06.12.24', value: 110 },
    { station: 'Кемеровская ГРЭС', date: '06.12.23', value: 156 },
    { station: 'Новосибирская ТЭЦ-3', date: '05.07.24', value: 211 },
];

const SafetyLayout = ({ slug, title, valueOfMaterialsPreLabel, valueOfMaterials, image, template }: ProjectInfo) => {
    const offset = getCSSCustomProp(document.documentElement, '--app-border-radius', 'number') as number;
    const willChange = useWillChange();
    const isMobile = window.matchMedia('(max-width: 1279px)').matches;
    const [width, setWidth] = useState(window.innerWidth);

    const areasOfApplicationRef = useRef<HTMLDivElement>(null);
    const projectsRef = useRef<HTMLDivElement>(null);
    const currentProjectRef = useRef<HTMLDivElement>(null);

    const [isShowAreas, setIsShowAreas] = useState(false);

    const [areasOfApplicationWidth, setAreasOfApplicationWidth] = useState(0);
    const [projectsWidth, setProjectsWidth] = useState(0);
    const [currentProjectWidth, setCurrentProjectWidth] = useState(0);

    useEffect(() => {
        const debouncedOnResize = () => {
            debounce(() => {
                setWidth(window.innerWidth);
            }, 50);
        };

        window.addEventListener('resize', debouncedOnResize);

        return () => {
            window.removeEventListener('resize', debouncedOnResize);
        };
    }, []);

    useLayoutEffect(() => {
        if (areasOfApplicationRef.current) {
            setAreasOfApplicationWidth(areasOfApplicationRef.current.offsetWidth);
        }
        if (projectsRef.current) {
            setProjectsWidth(projectsRef.current.offsetWidth);
        }
        if (currentProjectRef.current) {
            setCurrentProjectWidth(currentProjectRef.current.offsetWidth);
        }
    }, [projectsWidth, currentProjectWidth, offset, width]);

    return (
        <motion.div
            variants={{
                areas: {
                    x: isMobile ? -areasOfApplicationWidth - 8 : -areasOfApplicationWidth,
                },
                projects: {
                    x: isMobile ? -projectsWidth - 8 : -projectsWidth,
                },
                currentProject: {
                    x: -currentProjectWidth - 8,
                },
                initial: {
                    x: 0,
                    transition: {
                        delay: 0.67,
                        duration: 0.67,
                        ease: [0.33, 0, 0.67, 1],
                    },
                },
            }}
            transition={transition}
            animate={isShowAreas ? 'areas' : 'initial'}
            className="tps-container"
            style={{ willChange }}
        >
            <motion.div className="tps-project">
                <button
                    className="tps-close-button button button-close"
                    aria-label="Закрыть"
                    data-lit-popup-close="safety-popup"
                >
                    <div className="button__inner">
                        <CloseIcon />
                    </div>
                    <div className="button__inner button__inner-copy">
                        <CloseIcon />
                    </div>
                </button>

                <div className="tps-content">
                    <div className="tps-image">
                        <motion.picture
                            initial={{ y: '-105%' }}
                            animate={{
                                y: 0,
                                transition: {
                                    delay: 0.1,
                                    duration: 0.67,
                                    ease: [0.33, 0, 0.67, 1],
                                },
                            }}
                            exit={{ y: '105%' }}
                            key={slug}
                            style={{ willChange }}
                        >
                            <source
                                media="(max-width: 1279px)"
                                srcSet={`/static/img/projects/${image}@resize-1000x.webp`}
                                type="image/webp"
                            />
                            <source
                                media="(max-width: 1920px)"
                                srcSet={`/static/img/projects/${image}@resize-1900x.webp`}
                                type="image/webp"
                            />
                            <source
                                media="(min-width: 1921px)"
                                srcSet={`/static/img/projects/${image}@resize-2500x.webp`}
                                type="image/webp"
                            />
                            <source srcSet={`/static/img/projects/${image}@resize-2500x.webp`} type="image/webp" />
                            <source
                                media="(max-width: 1279px)"
                                srcSet={`/static/img/projects/${image}@resize-1000x.jpg`}
                            />
                            <source
                                media="(max-width: 1920px)"
                                srcSet={`/static/img/projects/${image}@resize-1900x.jpg`}
                            />
                            <source
                                media="(min-width: 1921px)"
                                srcSet={`/static/img/projects/${image}@resize-2500x.jpg`}
                            />
                            <img
                                alt={`Фотография - ${title}`}
                                src={`/static/img/projects/${image}@resize-2500x.jpg`}
                                width="2416"
                                height="1812"
                            />
                        </motion.picture>
                    </div>
                    <div className={classNames('project-body', template)}>
                        <div className="project-left">
                            <div className="project-title h4">
                                <ClipReveal slug={slug} delay={0.3}>
                                    {tp('Безопасность материалов')}
                                </ClipReveal>
                            </div>
                            <ClipReveal slug={slug}>
                                <div
                                    className="project-description text-lg"
                                    dangerouslySetInnerHTML={{
                                        __html: tp(
                                            'Независимые лаборатории не менее одного раза в год определяют суммарную удельную эффективную активность естественных радионуклидов в золе-уноса.',
                                        ),
                                    }}
                                />
                            </ClipReveal>
                            <div className="project-value">
                                <div className="project-value__label text-sm">
                                    <ClipReveal slug={slug} delay={0.4}>
                                        {tp('для всех видов строительства Показатель не должен превышать')}
                                    </ClipReveal>
                                </div>

                                <ClipReveal slug={slug} delay={0.4}>
                                    <div
                                        className={classNames('project-value__value h3', {
                                            'project-value-with-pre': valueOfMaterialsPreLabel,
                                        })}
                                    >
                                        <div className="project-value__num">
                                            <CountUp start={0} delay={0.4} end={370} separator=" " />
                                        </div>
                                        <div className="project-value__units">{'Бк/кг'}</div>
                                    </div>
                                </ClipReveal>
                                <div className="project-caption text-sm">
                                    {tp('(п. 5.3.4. СанПиН 2.6.1.2523-096, ГОСТ 30108-94)')}
                                </div>
                            </div>
                        </div>

                        <div className="project-right">
                            <div className="tps-data__areas">
                                <ClipReveal delay={0.67}>
                                    <button
                                        className="button-with-round-icon button-with-round-icon--primary"
                                        onClick={() => {
                                            setIsShowAreas(true);
                                        }}
                                    >
                                        <div className="button__icon">
                                            <WhiteArrowIcon />
                                        </div>
                                        <div className="button__text text-sm">
                                            Смотреть результаты
                                            <br />
                                            радиологических испытаний
                                        </div>
                                        <div className="button__text button__text-copy text-sm">
                                            Смотреть результаты
                                            <br />
                                            радиологических испытаний
                                        </div>
                                    </button>
                                </ClipReveal>
                            </div>
                            <div className="project-caption text-sm">
                                <ClipReveal slug={slug} delay={0.4}>
                                    {tp(
                                        'Исследования проводились лабораториями ЛРК «ШАНЭКО Сибирь», НТЦ «СибНИИцемент», ИЦ ФБУ «Красноярский ЦСМ», ИЦ ФБУ «Новосибирский ЦСМ»,  ИЛ ООО «ЦЭИ». Протоколы испытаний: sibgenco.ru/zola',
                                    )}
                                </ClipReveal>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
            <motion.div
                className="tps-areas-of-application"
                ref={areasOfApplicationRef}
                initial={false}
                variants={{
                    visible: {
                        opacity: 1,
                        pointerEvents: 'auto',
                    },
                    hidden: {
                        opacity: 0,
                        pointerEvents: 'none',
                        transition: {
                            duration: 0.7,
                            delay: 0.35,
                        },
                    },
                    initial: {
                        opacity: 0,
                        pointerEvents: 'none',
                    },
                }}
                transition={{
                    ease: [0.33, 0, 0.67, 1],
                }}
                animate={isShowAreas ? 'visible' : 'hidden'}
            >
                <button
                    className="tps-close-button button button-close"
                    aria-label="Вернуться к описанию ТЭЦ"
                    onClick={() => {
                        setIsShowAreas(false);
                    }}
                >
                    <div className="button__inner">
                        <CloseIcon />
                    </div>
                    <div className="button__inner button__inner-copy">
                        <CloseIcon />
                    </div>
                </button>
                <div className="tps-areas">
                    <div className="tps-areas__title h3">
                        <AnimatePresence mode="wait">
                            {isShowAreas ? (
                                <ClipReveal delay={0.35} slug={isShowAreas} key={isShowAreas}>
                                    {tp('Результаты радиологических испытаний')}
                                </ClipReveal>
                            ) : (
                                <div style={{ opacity: 0 }}>{tp('Результаты радиологических испытаний')}</div>
                            )}
                        </AnimatePresence>
                    </div>
                    <div className="safety-second-block">
                        <AnimatePresence mode="wait">
                            {isShowAreas ? (
                                <ul className="tps-areas__list list-unstyled" key={isShowAreas}>
                                    <ClipReveal delay={0.35 + 0.15 * 1} slug={isShowAreas} className="tps-area">
                                        {isMobile ? (
                                            <div>
                                                {tableData.map((item, index) => (
                                                    <div key={index} className="mobile-safety-item">
                                                        <div className="mobile-safety-item-left">
                                                            <div className="mobile-safety-item-title">Станция</div>
                                                            <div className="mobile-safety-item-description">
                                                                {item.station}
                                                            </div>
                                                        </div>
                                                        <div className="mobile-safety-item-right">
                                                            <div className="mobile-safety-item-right-top">
                                                                <div className="mobile-safety-item-title">
                                                                    Дата проведения испытания
                                                                </div>
                                                                <div className="mobile-safety-item-description">
                                                                    {item.date}
                                                                </div>
                                                            </div>
                                                            <div className="mobile-safety-item-right-bottom">
                                                                <div className="mobile-safety-item-title">
                                                                    Аэфф, Бк/кг
                                                                </div>
                                                                <div className="mobile-safety-item-description">
                                                                    {item.value}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <li className="tps-area__li safety-table">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Станция</th>
                                                            <th>Дата проведения испытания</th>
                                                            <th>Аэфф, Бк/кг</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tableData.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.station}</td>
                                                                <td>{item.date}</td>
                                                                <td>{item.value}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </li>
                                        )}
                                    </ClipReveal>
                                    <ClipReveal delay={0.35 + 0.15 * 1} slug={isShowAreas} className="tps-area">
                                        {isMobile ? (
                                            <div className="safety-chart-wrapper-horizontal">
                                                {data.map((item) => (
                                                    <div key={item.label} className="safety-bar-wrapper-horizontal">
                                                        <motion.div
                                                            className="safety-bar-horizontal"
                                                            style={{
                                                                background: item.color,
                                                                width: `${(item.value / 370) * 100}%`,
                                                            }}
                                                            initial={{ width: 0 }}
                                                            animate={{ width: `${(item.value / 370) * 100}%` }}
                                                            transition={{ duration: 1.5 }}
                                                        />
                                                        <div className="safety-bar-info">
                                                            <div className="safety-label">{item.label}</div>
                                                            <div className="safety-value">{item.value}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <li className="tps-area__li">
                                                <div className="safety-chart-wrapper">
                                                    {data.map((item) => (
                                                        <div key={item.label} className="safety-bar-wrapper">
                                                            <div className="safety-label">{item.label}</div>
                                                            <motion.div
                                                                className="safety-bar"
                                                                style={{
                                                                    background: item.color,
                                                                    height: `${(item.value / 370) * 100}%`,
                                                                }}
                                                                initial={{ height: 0 }}
                                                                animate={{ height: `${(item.value / 370) * 100}%` }}
                                                                transition={{ duration: 1.5 }}
                                                            >
                                                                {item.value}
                                                            </motion.div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </li>
                                        )}
                                    </ClipReveal>
                                </ul>
                            ) : (
                                <ul className="tps-areas__list list-unstyled" style={{ opacity: 0 }}>
                                    <li className="tps-area safety-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Станция</th>
                                                    <th>Дата проведения испытания</th>
                                                    <th>Аэфф, Бк/кг</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.station}</td>
                                                        <td>{item.date}</td>
                                                        <td>{item.value}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </li>
                                    <li className="tps-area">
                                        <li className="tps-area__li">
                                            <div className="safety-chart-wrapper">
                                                {data.map((item) => (
                                                    <div key={item.label} className="safety-bar-wrapper">
                                                        <div className="safety-label">{item.label}</div>
                                                        <motion.div
                                                            className="safety-bar"
                                                            style={{
                                                                background: item.color,
                                                                height: `${(item.value / 370) * 100}%`,
                                                            }}
                                                            initial={{ height: 0 }}
                                                            animate={{ height: `${(item.value / 370) * 100}%` }}
                                                            transition={{ duration: 1.5 }}
                                                        >
                                                            {item.value}
                                                        </motion.div>
                                                    </div>
                                                ))}
                                            </div>
                                        </li>
                                    </li>
                                </ul>
                            )}
                        </AnimatePresence>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default SafetyLayout;
